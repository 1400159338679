
import { defineComponent, ref } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import EmployeeListDrawer from '@/layout/header/partials/employeeList/EmployeeListDrawer.vue';
import AddRoomDrawer from '@/layout/header/partials/trainning_institute/add_room_Drawer.vue';
import EditRoomDrawer from '@/layout/header/partials/trainning_institute/edit_room_drawer.vue';
import MapCourseDrawer from '@/layout/header/partials/trainning_institute/MapCourse_Drawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin';
export default defineComponent({
  name: 'profile-institute',
  mixins: [apiEndpoint],
  components: {
    Datatable,
    EmployeeListDrawer,
    AddRoomDrawer,
    EditRoomDrawer,
    MapCourseDrawer,
  },

  data() {
    return {
      load: false,
      summaryLoader: false,
      api_url: '',
      active_status: '',
      tableHeader: [
        {
          name: 'Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Designation',
          key: 'designation',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },

        {
          name: 'Mobile',
          key: 'mobile',
          sortable: false,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: false,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
      ],
      InfrastructureHeader: [
        {
          name: 'Room No',
          key: 'room_no',
          sortable: true,
        },
        {
          name: 'Location',
          key: 'location',
          sortable: true,
        },
        {
          name: 'Capacity',
          key: 'capacity',
          sortable: true,
        },
        {
          name: 'Room Equipment',
          key: 'equipment',
          sortable: true,
        },
      ],
      InfrasTructureBody: [],

      TrainningPartnerHeader: [
        {
          name: 'Training Partner',
          key: 'trainning_partner',
          class: 'text-align: center',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          class: 'text-align: center',
        },
      ],
      CourseHeader: [
        {
          name: 'Course Name',
          key: 'course_name',
          class: 'text-align: center',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          class: 'text-align: center',
        },
        {
          name: 'Association',
          key: 'association',
          class: 'text-align: center',
        },
        {
          name: 'Training Institute',
          key: 'trainning_institute',
          class: 'text-align: center',
        },
      ],
      TrainningPartnerBody: [],
      courseBody: [],
      tableColumn: [
        {
          name: 'Training Institute Type',
          key: 'training_institute_type',
          sortable: true,
        },
        {
          name: 'Institute Short Name',
          key: 'short_name',
          sortable: true,
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
        },
        {
          name: 'Description',
          key: 'institute_description',
          sortable: true,
        },
        {
          name: 'Registration Number',
          key: 'registration_number',
          sortable: true,
        },
        {
          name: 'Registration Date',
          key: 'registration_date',
          sortable: true,
        },
        {
          name: 'Registration Authority',
          key: 'registration_authority',
          sortable: true,
        },
        {
          name: 'Address',
          key: 'address',
          sortable: true,
        },
        {
          name: 'Postcode',
          key: 'postcode',
          sortable: true,
        },
        {
          name: 'Division',
          key: 'division',
          sortable: true,
        },
        {
          name: 'District',
          key: 'district',
          sortable: true,
        },
        {
          name: 'Sub District',
          key: 'sub_district',
          sortable: true,
        },
        {
          name: 'Telephone',
          key: 'telephone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Fax',
          key: 'fax',
          sortable: true,
        },
        {
          name: 'Web url',
          key: 'web_url',
          sortable: true,
        },
        {
          name: 'Number of training room',
          key: 'number_of_training_room',
          sortable: true,
        },
        {
          name: 'Training Capacity',
          key: 'training_capacity',
          sortable: true,
        },
        {
          name: 'Total Trainer Male',
          key: 'total_trainer_male',
          sortable: true,
        },
        {
          name: 'Total Trainer Female',
          key: 'total_trainer_female',
          sortable: true,
        },
        {
          name: 'Lab Equipment',
          key: 'lab_equipment',
          sortable: true,
        },
      ],
      trainerHeader: [
        {
          name: 'Trainer Name',
          key: 'trainer_name',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: 'Years of Exp',
          key: 'yearsOfexp',
          sortable: true,
        },
        {
          name: 'Last Batch count',
          key: 'lastBatchCount',
          sortable: true,
        },
      ],
      assessorHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Assessor Name',
          key: 'assessor_name',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Area of Expertise',
          key: 'area_of_expertise',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      updateLog: [],
      trainerList: [],
      assessorList: [],
      employeesList: [] as any,
      employees: [] as any,
      data: {},
      tabIndex: ref(0),
      instituteInfos: [],
      countList: [],
      componentKey: 0,
      employekey: 0,
      componentPartnerKey: 0,
      componentCourseKey: 0,
      showAction: true,
      employeeLoader: true,
      token: '' as any,
    };
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.token = urlParams.get('token');

    this.api_url = this.VUE_APP_API_URL;
    if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
      this.showAction = false;
    }
    this.emitter.on('employee-data-update-done', async () => {
      await this.getEmployee();
    });
    this.emitter.on('room-updated', async () => {
      await this.getRoomData();
    });
    this.emitter.on('trainning-updated', async () => {
      await this.getTrainningPartnerData();
    });
    this.emitter.on('courseMap-updated', async () => {
      await this.getCourseMapData();
    });
    this.load = true;
    await this.getInstitute();
    await this.getEmployee();
    await this.getUpdateLog();
    await this.getRoomData();
    await this.getTrainningPartnerData();
    await this.getCourseMapData();
    await this.getTrainerInfo();
    await this.getAssessorInfo();
    await this.getCount();

    Object.assign(this.tableData, this.lists);
  },
  methods: {
    async getCount() {
      this.summaryLoader = true;
      await ApiService.getMobile(
        'institute/showcount/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.countList = response.data.data;
          // console.log(response);
          this.summaryLoader = false;
        })
        .catch((response) => {
          this.summaryLoader = false;
          console.log(response);
        });
    },
    Approve() {
      let data = {
        active_status: 1,
      };

      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.updateMobile(
            'institute/approve/' + this.instituteInfoId,
            data,
            this.token
          )
            .then((response) => {
              this.$router.go(0);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    // Reject() {
    //   let data = {
    //     active_status: 1,
    //   };
    //   Swal.fire({
    //     title: 'Are you sure you want to reject it?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, Rejected!',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       ApiService.update(
    //         'institute/status_change/' + this.instituteInfoId,
    //         data,
    //         this.token
    //       )
    //         .then((response) => {
    //           this.$router.go(0);
    //           Swal.fire('Rejected!', response.data.data, 'success');
    //         })
    //         .catch(({ response }) => {
    //           console.log(response);
    //         });
    //     }
    //   });
    // },
    add() {
      this.$router.push({
        name: 'institute employee',
        params: { id: this.instituteInfoId },
      });
    },

    async getRoomData() {
      await ApiService.getMobile(
        'institute/detail/list/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.InfrasTructureBody = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainningPartnerData() {
      await ApiService.getMobile(
        'institute/detail/get_entity_institute_info/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.TrainningPartnerBody = response.data.data;
          this.componentPartnerKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainerInfo() {
      this.load = true;
      ApiService.getMobile(
        'entity/trainer/map_trainer_info?institute_info_id=' +
          this.instituteInfoId +
          '&mapping_type=institute',
        this.token
      )
        .then((response) => {
          this.load = false;
          this.trainerList = response.data.data;
          console.log(response);
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async getAssessorInfo() {
      ApiService.getMobile(
        'entity/assessor/map_assessor_info?institute_info_id=' +
          this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.assessorList = response.data.data;
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getCourseMapData() {
      await ApiService.getMobile(
        'institute/detail/get_course_institute_info/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.courseBody = response.data.data;
          console.log(response.data.data);
          this.componentCourseKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },

    async getInstitute() {
      await ApiService.getMobile(
        'institute/show/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.load = false;
          this.instituteInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUpdateLog() {
      await ApiService.getMobile(
        'institute/update_log/' + this.instituteInfoId,
        this.token
      )
        .then((response) => {
          this.updateLog = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployee() {
      this.employeeLoader = true;
      await ApiService.getMobile(
        'institute/institute_employee?institute_info_id=' +
          this.instituteInfoId +
          '&active_status=' +
          this.active_status,
        this.token
      )
        .then((response) => {
          this.employees = [];
          this.employeeLoader = false;
          this.employees = response.data.data;
          // for (let i = 0; i < this.employeesList.length; i++) {
          //   if (
          //     this.employeesList[i].entitytyperole.role_name !== "Trainer" &&
          //     this.employeesList[i].entitytyperole.role_name !== "Assessor"
          //   ) {
          //     console.log(this.employeesList[i].entitytyperole.role_name);
          //     this.employees.push(this.employeesList[i]);
          //   }
          // }
          // console.log(this.employees);
          this.employekey += 1;
        })
        .catch(({ response }) => {
          this.employeeLoader = false;
          console.log(response);
        });
    },
    searchItems() {
      if (this.search !== '') {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === 'object')) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    edit(data) {
      this.emitter.emit('edit-employee-data', data);
    },

    addRoom() {
      this.emitter.emit('entity-add-room-data', this.instituteInfoId);
    },
    editRoom(data) {
      this.emitter.emit('entity-edit-room-data', data);
    },
    editTrainningPartner(data) {
      this.emitter.emit('entity-edit-trainning-partner', data);
    },

    addTrainningPartner() {
      this.emitter.emit(
        'entity-add-trainningPartner-data',
        this.instituteInfoId
      );
    },
    addCourseMap() {
      this.emitter.emit('entity-add-mapCourse-data', this.instituteInfoId);
    },

    view(id) {
      this.$router.push({
        name: 'entityEmployeeProfiles',
        params: { entity: this.instituteInfoId, id: id },
      });
    },
    activateTrainningPartner(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to Activate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institute/detail/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('trainning-updated', true);
              Swal.fire('Activated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    DeactivateTrainningPartner(id) {
      let data = {
        active_status: 0,
      };
      Swal.fire({
        title: 'Are you sure you want to Deactivate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institute/detail/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('trainning-updated', true);
              Swal.fire('Deactivated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    activateCourseMap(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to Activate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            'institute/detail/status_coursemap_change/' + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('courseMap-updated', true);
              Swal.fire('Activated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    DeactivateCourseMap(id) {
      let data = {
        active_status: 0,
      };
      Swal.fire({
        title: 'Are you sure you want to Deactivate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            'institute/detail/status_coursemap_change/' + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('courseMap-updated', true);
              Swal.fire('DeActivated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    RoomDelete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('institute/detail/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('room-updated', true);
              Swal.fire('Deleted!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Delete(id) {
      console.log(id);
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('configurations/employee/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('employee-updated', true);
              Swal.fire('Deleted!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const route = useRoute();
    const instituteInfoId = route.params.id;
    return { instituteInfoId };
  },
});
